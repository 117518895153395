import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesPage from './routes/RoutesPage.js';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";


function App() {
  return (
    <Router>

    <RoutesPage  />

  </Router>
  );
}

export default App;
