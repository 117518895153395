import React from "react";
import { Routes, Route } from "react-router-dom";

// Main Content (Top Nav Links)
import HomePage from '../pages/Home.js';


class RoutesPage extends React.Component {
  render() {
    return (
        <Routes>
          <Route path="/" element={<HomePage />}>
          </Route>
        </Routes>
    );
  }
}
export default RoutesPage;
