import React from "react";
import axios from 'axios';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBContainer,
  MDBRow,
  MDBCol,
  button,
  MDBModal,
  MDBInput,
} from "mdbreact";
import $ from "jquery";


class ForBusiness extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meterReads: [{ counterTimeFrameId: "", readDate: "", readValue: 0 }],
      readDate: "",
      environmentSelected: "",
      environmentUrlSelected: "",
      environmentApiKeySelected: "",
      maxDate:"",
      countertimeframeidDisabled:false,
      meterpointnumberDisabled:false,
      companyIdDisabled:false,
      deliveryTypeDisabled:false,
      showBudgetLogo:false,
      showFlogasLogo:false,
      showGas:true,
      showCompnayId:true,
      showCounterFrameId:true,
      mprnorgprn:"",
      showForm:false
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(event.target.value);
  };
  changeHandlerDeliveryType = (event) => {

    this.setState({
      [event.target.name]: event.target.value,
    });

    this.setState({
      deliverytype: event.target.value,
    });

    console.log(event.target.value);

  };

  getTodaysFormattedDate() {
    const today = new Date();
  
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = today.getDate().toString().padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;
  
    return formattedDate;
  }


  async componentDidMount() {
    $("#signUpBtnBilling").show();
    $("#newLoader").hide();
    $("#dynamicError").hide();
    $("#showtakepaymentform").show();

    $("#showGas").show();
    $("#showCompnayId").show();
    $("#showCounterFrameId").show();

    var urlParams = new URLSearchParams(window.location.search);
    var companyId = urlParams.get("companyId");
    var meterpointnum = urlParams.get("meterpointnum");
    var counterframeid = urlParams.get("counterframeid");


    await axios.get(`https://bepublicwebne.azurewebsites.net/api/api/appoptions/42`)
    .then(res => {
  
      console.log(res.data);
      if (res.data[0].optionValue === "False") {
        this.setState({
          showDynamicError:true,
     
        });
        if(companyId.includes("be"))
        {
          this.setState({
            dynamicError:"This section is under maintenance we apologise for the inconvenience. Please Email TalkToUs@budgetenergy.co.uk or Phone: 0800 012 1177 "
          });
        }
        else{
          this.setState({    
            dynamicError:"This section is under maintenance we apologise for the inconvenience. Please Email natgas@flogasni.com or Phone: 028 9073 0277 (048 9073 0277 from the Republic of Ireland)"
          });
        }
        $("#signUpBtnBilling").show();
      }
      else{
        this.setState({
          showDynamicError:false,
          dynamicError:"",
          showForm:true
        });
        $("#signUpBtnBilling").show();
      }
  
    });
    

    
    const todaysDate = this.getTodaysFormattedDate();

    this.setState({maxDate:todaysDate})


    if(companyId.includes("be"))
    {
      this.setState({
        showBudgetLogo:true,
        showFlogasLogo:false
      });
    }
    else{
      this.setState({

        showBudgetLogo:false,
        showFlogasLogo:true
      });
    }

    console.log("params")
    console.log(companyId)
    console.log(meterpointnum)
    console.log(counterframeid)


    if(companyId !== null || companyId !== ""){
      this.setState({
        companyId:companyId,
        companyIdDisabled:true
      });

      $("#showCompnayId").hide();
    }
    else{
      this.setState({
        meterpointnumberDisabled:false
      });
      $("#showCompnayId").hide();
    }


    if(counterframeid !== null || counterframeid !== ""){
      this.setState({
        meterReads: [{ counterTimeFrameId: counterframeid, readDate: Date().Now, readValue: 0 }],
        countertimeframeidDisabled:true       
      });
      $("#showCounterFrameId").hide();

      if(counterframeid === "Volume")
      {
        this.setState({
          mprnorgprn:"GPRN Number"
        });
      }
      else{
        this.setState({
          mprnorgprn:"MPRN Number"
        });
      }
    }
    else{
      this.setState({
        meterpointnumberDisabled:false        
      });
      $("#showCounterFrameId").show();
    }
    if(meterpointnum !== null || meterpointnum !== ""){
      this.setState({
        meterpointnumber:meterpointnum,
        meterpointnumberDisabled:true
      });
    }
    else{
      this.setState({
        meterpointnumberDisabled:false
      });
    }
    document.getElementById('readDate').valueAsDate = new Date();
    this.setState({readDate:document.getElementById('readDate').value })
    
    this.setState({
      deliverytype: "Gas",
      deliveryTypeDisabled:true
    });
    $("#showGas").hide();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  myFunction() {
    //show take payment and hide the show vend section
    this.setState({
      showGeneratedKeypadNum: false,
      showtakepaymentform: true,
      processingLoader: false,
    });
  }

  handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const { meterReads } = this.state;
    const updatedMeterReads = [...meterReads];
    updatedMeterReads[index][name] = value;
    this.setState({ meterReads: updatedMeterReads });

    const numericValue = parseFloat(updatedMeterReads[0].readValue);
    updatedMeterReads[0].readValue = numericValue;


    console.log(updatedMeterReads[0].readValue)

    if (/\D/.test(updatedMeterReads[0].readValue)) {
      console.log("Error: Only numbers are allowed!");

      this.setState({
        showDynamicError:true,
        dynamicError:"Only Numbers are allowed for the reading value",
        dynamicErrorColor:"danger mb-3 mt-3"
      })



    } else {
      this.setState({
        showDynamicError:false,
        dynamicError:""
      })
    }


  };

  addMeterRead = () => {
    const { meterReads } = this.state;
    this.setState({
      meterReads: [
        ...meterReads,
        { counterTimeFrameId: "", readDate: "", readValue: 0 },
      ],
    });
  };

  submitHandler = async (event) => {
    event.preventDefault();

    this.setState({ processingLoader: true });
    $("#signUpBtnBilling").hide();

    const companyId = document.getElementById("companyIdInput").value;
    const meterPointNumber = document.getElementById("meterPointNumberInput").value;
    const deliveryType = document.getElementById("deliveryTypeInput").value;


    //  const companyId = this.state.companyId;
    //  const meterPointNumber = this.state.meterpointnumber;
    //  const deliveryType = this.state.deliverytype;

    const jsonObject = {
      companyId: companyId,
      meterPointNumber: meterPointNumber,
      deliveryType: deliveryType,
      meterReads: this.state.meterReads,
    };

    console.log(jsonObject);
    const myJSON = JSON.stringify(jsonObject);
    console.log(myJSON);

    //get the value of the form to check if all fields are valid
    var valid = "";
    valid = event.target.reportValidity();
    console.log("Is form Valid");
    console.log(valid);
    //Check if the form is valid




    if (valid === true) {
      //regex check to avoid inoput of special characters
      var iscompanyId = /[!@#$%£^&*(),.?"':{}|<>/]/.test(companyId);
      var ismeterPointNumber = /[!@#$£%^&*{}<>/]/.test(meterPointNumber);
      //var ispciAuthCodeOk = /[!@#$%^&*(),.?"':{}|<>/]/.test(this.state.pciAuthCode);
      var isdeliveryType = /[!@#$£%^&*(),.?"':{}|<>/]/.test(deliveryType);

      if (iscompanyId || ismeterPointNumber || isdeliveryType) {
        this.setState({
          dynamicError:
            "Please ensure that your enteries contain no special characters eg (!@#$%^&*)",
          showDynamicError: true,
          dynamicErrorColor: "danger",
        });
        $("#signUpBtnBilling").show();
        this.setState({ processingLoader: false });
      } else {
         

        var envurl = process.env.REACT_APP_API_URL_PROD;
        var key = process.env.REACT_APP_API_KEY_PROD;

        console.log("Keys for submission");
        console.log(myJSON);


        var config = {
          method: "post",
          url: envurl + "importmeterread",
          headers: {
            "Content-Type": "application/json",
            "x-functions-key": key,
          },
          data: myJSON,
        };



        await axios(config)
          .then(function(response) {
            console.log(JSON.stringify(response));
            console.log(JSON.stringify(response.data));

            if (response.status === 200) {

              alert("Meter Submitted Succesfully");
              setTimeout(
                function() {

                  switch(companyId)
                  {
                    case 'beni': window.location.href = "https://budgetenergy.co.uk/"; break;
                    case 'flri': window.location.href = "https://www.flogas.ie/"; break;
                    case 'flni': window.location.href = "https://www.flogasni.com/"; break;
                    default: window.location.href = "/"; break;
                  }

                  
                  //alert.window("Processed");
                }.bind(this),
                2000
              );
            }
          })
          .catch(function(error) {
            alert(error);
          
          });

     
        this.setState({ processingLoader: false });
      }

      
    } else {
      alert("Error Submitting the form please ensure fields are filled out correctly");
      $("#signUpBtnBilling").show();
      this.setState({ processingLoader: false });
    }
  };

  render() {
    this.logoImage = "be-logo-new.png";

    const { meterReads } = this.state;
    const { mprnorgprn } = this.state;
    const { maxDate } = this.state;
    const { countertimeframeidDisabled } = this.state;
    const { meterpointnumberDisabled } = this.state;
    const { companyIdDisabled } = this.state;
    const { deliveryTypeDisabled } = this.state;


    const options = [
      { value: "", text: "--Delivery Type--" },
      { value: "Gas", text: "Gas" },
      { value: "Electricity", text: "Electricity" },
    ];
  
    
    console.log("readdate");
    console.log(meterReads);

    return (
      <div>
        <header>



            {
              this.state.showBudgetLogo?
              <section class="top-nav sticky-top">
              <div>
                <img
                  id="Logo"
                  className="largeLogo"
                  onClick={() => (window.location.href = "/home")}
                  src={process.env.PUBLIC_URL + "/be-logo-new.png"}
                />
              </div>
              <input id="menu-toggle" type="checkbox" />
              <label class="menu-button-container" for="menu-toggle">
                <div class="menu-button"></div>
              </label>
              <ul class="menu">
              </ul>
            </section>
  
              :null
            }


             {
              this.state.showFlogasLogo?
              <section class="top-nav-flogas sticky-top-flogas">
              <div>
                <img
                  id="Logo"
                  className="largeLogo"
                  onClick={() => (window.location.href = "/home")}
                  src={process.env.PUBLIC_URL + "/Logo.png"}
                />
              </div>
             
              <label class="menu-button-container-flogas" for="menu-toggle-flogas">
                <div class="menu-button-flogas"></div>
              </label>
              <ul class="menu-flogas">
              </ul>
            </section>
              :null
            }



           
 
                    <MDBContainer id="signUp2" fluid className="mt-5 mb-5 mobilePadding">
                    


                    <form
                      id="solarform"
                      className="needs-validation"
                      onSubmit={this.submitHandler}
                      noValidate
                    >
                      <MDBRow>
                        <MDBCol size="12">
                          <p className="display-7 text-center text-uppercase">
                            Submit Meter Reads
                          </p>
                          <hr className="divider" />
                        </MDBCol>
                      </MDBRow>
        
        
            
                        {/* <div id="errorMessage">{
                          this.state.errorMessage ?
                          <MDBRow>
                            <MDBCol size="3" />
                            <MDBCol lg="6" md="12">
                              <p
                                className={this.state.dynamicErrorColor}
                                color={this.state.dynamicErrorColor}
                              >
                                {this.state.dynamicError}
                              </p>
                            </MDBCol>
                            <MDBCol size="3" />
                          </MDBRow>
                            : null}
                        </div> */}
        
        
        
                      {/*Dynamic error*/}
                      {this.state.showDynamicError ? (
                        <div>
                            <MDBRow>
                              <MDBCol size="3" />
                              <MDBCol lg="6" md="6">
                                <div className="form-error">
                                <p>
                                  {this.state.dynamicError}
                                </p>
                                </div>
                              </MDBCol>
                              <MDBCol size="3" />
                            </MDBRow>
                        </div>
                      ) : null}
        
                      <div id="showGeneratedPayment" />
        

                      {
                      this.state.showForm?
                      <div>
        
        
                          <MDBRow className="mb-3" id="showCompnayId">
                          {/*Form Left Column*/}
                          <MDBCol lg="3" md="12" />
                          <MDBCol lg="6" md="12">
                            <div className="dark-grey-text">
                              <input
                                value={this.state.companyId}
                                //value="flri"
                                onChange={this.changeHandler}
                                type="text"
                                id="companyIdInput"
                                className="form-control"
                                name="companyId"
                                maxLength="40"
                                placeholder="companyId"
                                pattern="^[a-zA-Z0-9 ]{2,225}$"
                                disabled={companyIdDisabled}
                                required
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
        
                        <MDBRow className="">
                          {/*Form Left Column*/}
                          <MDBCol lg="3" md="12" />
                          <MDBCol lg="6" md="12">
                            <div className="dark-grey-text">
                            <label className="labelCss" for="username">{mprnorgprn}</label><br></br>
                              <input
                                value={this.state.meterpointnumber}
                                //value="10019036610"
                                onChange={this.changeHandler}
                                type="text"
                                id="meterPointNumberInput"
                                className="form-control"
                                name="meterpointnumber"
                                maxLength="50"
                                placeholder="meterpointnumber"
                                pattern="^[a-zA-Z0-9 ]{2,225}$"
                                disabled={meterpointnumberDisabled}
                                required
                              />
                            </div>
                          </MDBCol>
                        </MDBRow>
        
        
          
                          <MDBRow className="" id="showGas">
                          {/*Form Left Column*/}
                          <MDBCol lg="3" md="12" />
                          <MDBCol lg="6" md="12" className="dark-grey-text deliveryDateCss">
                            <div className="dark-grey-text ">
                              <div id="selectTitle" className="md-form">
                                <select
                                  required
                                  id="deliveryTypeInput"
                                  className="form-select-box selectBoxCss"
                                  value={this.state.deliverytype}
                                  disabled={deliveryTypeDisabled}
                                  onChange={this.changeHandlerDeliveryType}
                                >
                                  {options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </MDBCol>
                        </MDBRow>
              
        
                        <MDBRow className="">
                          {/*Form Left Column*/}
                          <MDBCol lg="3" md="12" />
        
                          <MDBCol lg="6" md="12" className="dark-grey-text readDateCss" >
                            <div className="dark-grey-text">
                              <input
                                value={this.state.readDate}
                                onChange={this.changeHandler}
                                type="date"
                                id="readDate"
                                className="form-control"
                                name="readDate"
                                maxLength="150"
                                max={maxDate}                    
                                required
                              />
                              <i class="fa-solid fa-star-of-life iconcolor"></i>
                            </div>
                          </MDBCol>
                        </MDBRow>
        
                        {meterReads.map((meterRead, index) => {
                          meterRead.readDate = this.state.readDate;
        
                          return (
                            <div key={index}>
                              <MDBRow>
                                <MDBCol size="12">
                                  <p className="display-7 text-center text-uppercase">
                                    Meter Read {index + 1}
                                  </p>
                                  <hr className="divider" />
                                </MDBCol>
                              </MDBRow>
        
        
                                  <MDBRow className="mb-3" id="showCounterFrameId">
                                  {/*Form Left Column*/}
                                  <MDBCol lg="3" md="12" />
                                  <MDBCol lg="6" md="12">
                                    <div className="dark-grey-text">
                                      <input
                                        value={meterRead.counterTimeFrameId}
                                        onChange={(event) =>
                                          this.handleInputChange(index, event)
                                        }
                                        type="text"
                                        id="counterTimeFrameId"
                                        className="form-control"
                                        name="counterTimeFrameId"
                                        maxLength="50"
                                        placeholder="Counter timeframe id"
                                        pattern="^[a-zA-Z0-9_]+$"
                                        disabled={countertimeframeidDisabled}
                                        required
                                      />
                                    </div>
                                  </MDBCol>
                                </MDBRow>
        
        
                              <MDBRow className="mb-3">
                                <MDBCol lg="3" md="12" />
                                <MDBCol lg="6" md="12">
                                  <div className="dark-grey-text">
                                    <input
                                      value={meterRead.readValue}
                                      onChange={(event) =>
                                        this.handleInputChange(index, event)
                                      }
                                      type="number"
                                      id="readValue"
                                      className="form-control"
                                      name="readValue"
                                      maxLength="7"
                                      pattern="^\d{0,8}$"
                                      placeholder=" Read Value"                              
                                      required
                                    />
                                    <i class="fa-solid fa-star-of-life iconcolor"></i>
                                  </div>
                                </MDBCol>
                              </MDBRow>
                            </div>
                          );
                        })}
        
                        {/* <MDBRow id="signUpBtnBilling">
                <MDBCol lg="3" md="12"/>
                <MDBCol lg="6" md="4">
                  <div className="d-flex justify-content-center mt-3">
                    <button  className="btnFormat be-blue-button"onClick={this.addMeterRead}  id="addmeterread">Add Additonal Meter Register</button>            
                  </div>
                </MDBCol>
                </MDBRow>   */}
        
                        <MDBRow id="signUpBtnBilling">
                          {/*Form Left Column*/}
                          <MDBCol lg="3" md="12" />
                          <MDBCol lg="6" md="4">
                            <div className="d-flex justify-content-center mt-3">
                              {/* <button  className="btnFormat be-blue-button"  onClick={this.handleSubmit}>Submit Meter Read</button> */}
                              <button
                                className="btnFormat be-blue-button"
                                type="submit"
                                id="submitButton"
                              >
                                Submit Meter Read
                              </button>
                            </div>
                          </MDBCol>
                        </MDBRow>
        
                        {this.state.processingLoader ? (
                            <MDBRow id="processingLoader">
                            <MDBCol size="4" />
                            <MDBCol lg="12" md="4">
                              <div className="d-flex justify-content-center">
                              <div className="newloader"></div>               
                              </div>
                              <div className="d-flex justify-content-center">Processing</div>
                            </MDBCol>
                            <MDBCol size="4" />
                          </MDBRow>
                        ) : null}
                      </div>
                      :null
                    }

                   
                   
                    </form>


                  </MDBContainer>
    




        </header>
      </div>
    );
  }
}

export default ForBusiness;
